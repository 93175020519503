<template lang="pug">
.mt-8.mb-0
  v-flex(row, no-gutter, align-end)
    v-flex(md9)
    v-flex(md3, row)
      v-menu(
        ref="menu",
        v-model="menu",
        :close-on-content-click="false",
        :return-value.sync="initDate",
        transition="scale-transition",
        offset-y,
        max-width="290px",
        min-width="auto"
      )
        template(v-slot:activator="{ on, attrs }")
          v-text-field.pt-2.pl-2.pr-2.ma(
            v-model="initDate",
            label="Ano/Mês",
            prepend-icon="mdi-calendar",
            outlined,
            dense,
            readonly,
            v-bind="attrs",
            v-on="on"
          )
        v-date-picker(
          v-model="initDate",
          type="month",
          no-title,
          scrollable,
          locale="pt-BR",
          @change="$refs.menu.save(initDate)"
        )
      v-btn(icon, outlined, color="primary", @click="getAutenticacoes()")
        v-icon mdi-sync
  v-flex
    DataFilter.mt-2(
      :filterProps="filters",
      :items="dataset",
      :paginate="false"
    )
      template(v-slot:default="{ items }")
        v-data-table.ma-6.mb-0.autenticacaoTable(
          :headers="headersHash",
          :items="items",
          :single-expand="false",
          :expanded.sync="expanded",
          :loading="items.length == 0",
          loading-text="Carregando... Aguarde um momento",
          item-key="id",
          :items-per-page="30",
          :footer-props="footerProps",
          show-expand
        )
          template(v-slot:item.status="{ headers, item }")
            v-tooltip(bottom)
              template(v-slot:activator="{ on }")
                v-icon(
                  v-on="on",
                  alt="Status Autenticação",
                  :color="getStatusColor(item.status).color"
                ) mdi-circle
              span {{ getStatusColor(item.status).text }}

          template(v-slot:item.geradoem="{ headers, item }")
            | {{ new Date(item.geradoem).toLocaleString() }}

          template(v-slot:expanded-item="{ headers, item }")
            td(:colspan="headers.length")
              v-data-table.ma-2.clientesLogsTable(
                dense,
                :headers="headersEmpresas",
                :items="item.empresas",
                hide-default-footer,
                :items-per-page="item.empresas.length",
                width="100%"
              )
                template(v-slot:item.origem="{ item }")
                  v-tooltip(v-if="item.origem.substring(0, 1) === '2'", bottom)
                    template(v-slot:activator="{ on }")
                      span(v-on="on") {{ item.origem }}
                    span {{ item.motivo }}
                  span(v-else) {{ item.origem }}

                template(v-slot:item.dataretorno="{ item }")
                  span {{ item.dataretorno > 0 ? new Date(item.dataretorno).toLocaleString() : "Sem retorno" }}

                template(v-slot:item.datavencimento="{ item }")
                  span {{ item.datavencimento.split("-").reverse().join("/") }}

                template(v-slot:item.registros="{ item }")
                  span {{ item.historico.length == 1 ? "1 Registro" : item.historico.length + " Registros" }}

                template(v-slot:item.status="{ item }")
                  span {{ getStatusColor(item.status).text }}

                template(v-slot:item.historico="{ item }")
                  v-tooltip(bottom)
                    template(v-slot:activator="{ on }")
                      v-btn(
                        v-on="on",
                        fab,
                        outlined,
                        x-small,
                        color="primary",
                        @click="openDialogHistorico(item)"
                      )
                        v-icon(small) fa-solid fa-inbox
                    span Histórico

                template(v-slot:item.actions="{ item }")
                  v-tooltip(bottom)
                    template(v-slot:activator="{ on }")
                      v-btn.ml-2(
                        v-on="on",
                        fab,
                        outlined,
                        x-small,
                        color="primary",
                        @click="openDialogChave(item)"
                      )
                        v-icon(small) fa-regular fa-copy
                    span Copiar chave

                  v-tooltip(v-if="item.copia.length > 0", bottom)
                    template(v-slot:activator="{ on }")
                      v-btn.ml-2(
                        v-on="on",
                        fab,
                        outlined,
                        x-small,
                        color="primary",
                        @click="openDialogHistoryCopiaChave(item.copia)"
                      )
                        v-icon(small) fa-solid fa-clock-rotate-left
                    span Historico de cópia de chave

  v-dialog(v-model="dialogChave", max-width="500px")
    v-card
      v-card-title
        span Copiar Chave
        v-spacer
        v-btn(icon, @click="clearCopiarChave()")
          v-icon mdi-close
      v-card-text.pt-2
        p Para copiar a chave, informe o motivo de enviar a chave para o cliente, e clique no botão Copiar.
        v-text-field(
          v-model="motivoChave",
          label="Motivo",
          outlined,
          minlength="10",
          maxlength="100",
          counter="100",
          :rules="[(v) => v.length >= 10 || v.length == 0 || 'Motivo informado muito curto']"
        )
      v-card-actions.pa-0.pb-2
        v-spacer
        v-btn(
          color="primary",
          text,
          :disabled="motivoChave.length < 10",
          @click="copyToClipboard()"
        ) Copiar

  v-dialog(v-model="dialogHistoryCopiaChave", max-width="500px")
    v-card
      v-card-title
        span Historico de cópia de chave
        v-spacer
        v-btn(icon, @click="dialogHistoryCopiaChave = false")
          v-icon mdi-close
      v-card-text.pt-2.mb-0
        v-divider
        v-timeline(align-top, dense)
          v-timeline-item(
            v-for="(row, i) in itemHistoricoChave",
            :key="i",
            right,
            small,
            color="success"
          )
            div {{ new Date(row.datahora).toLocaleString() }} - {{ row.usuario.split(":")[1] }}
            div Motivo: {{ row.motivo }}
        v-divider

  v-dialog(v-model="dialogHistorico", max-width="2500px")
    v-card
      v-card-title.pb-0
        span Histórico de Autenticação
        v-spacer 
        v-btn(icon, @click="dialogHistorico = false")
          v-icon mdi-close
      v-card-text.pt-0.mb-0
        h5 {{ itemHistorico[0].codigocliente }} - {{ itemHistorico[0].nome }} - {{ itemHistorico[0].cpfcnpj }}
        v-data-table.ma-2.mt-4(
          :headers="headersHistorico",
          :items="itemHistorico",
          hide-default-footer,
          dense,
          :items-per-page="itemHistorico.length",
          width="100%"
        )
          template(v-slot:item.dataretorno="{ item }")
            span {{ new Date(item.dataretorno).getTime() > 0 ? new Date(item.dataretorno).toLocaleString() : "" }}

          template(v-slot:item.datageracao="{ item }")
            span {{ new Date(item.datageracao).toLocaleString() }}

          template(v-slot:item.datavencimento="{ item }")
            span {{ item.datavencimento.split("-").reverse().join("/") }}

          template(v-slot:item.status="{ item }")
            span {{ getStatusColor(item.status).text }}

          template(v-slot:item.origem="{ item }")
            span {{ item.origem == 1 ? "1 - Autenticação mensal" : "2 - Manual" }}
        v-divider
</template>

<script>
import { mapMutations } from "vuex";
import csapi from "@/api/csapi";

export default {
  data: () => ({
    dialogChave: false,
    dialogHistorico: false,
    dialogHistoryCopiaChave: false,
    menu: false,
    initDate: new Date().toISOString().substr(0, 7),
    itemChave: {},
    itemHistoricoChave: [],
    itemHistorico: [
      {
        codigoCliente: "",
        nome: "",
        cpfcnpj: "",
      },
    ],
    filters: {
      includeSearch: true,
      items: [],
    },
    expanded: [],
    headersHash: [
      { text: "ID", value: "idweb", width: "50", sortable: false },
      { text: "Cliente", value: "nome" },
      { text: "Hash", value: "hash", width: "180" },
      { text: "ID Servidor", value: "idservidor", width: "140" },
      { text: "Gerado em", value: "geradoem" },
      { text: "Autenticado em", value: "autenticadoem" },
      {
        text: "Status",
        value: "status",
        align: "center",
        width: "100",
        sortable: true,
      },
      {
        text: "",
        value: "data-table-expand",
        align: "center",
        sortable: false,
      },
    ],
    headersEmpresas: [
      { text: "Self", value: "codigocliente", align: "center", width: "80" },
      { text: "Nome cliente self", value: "nome", width: "300" },
      { text: "CPF/CNPJ", value: "cpfcnpj", width: "160" },
      { text: "Origem Autenticação", value: "origem", width: "220" },
      { text: "Motivo Autenticação", value: "motivo", width: "220" },
      {
        text: "Liberado Até:",
        value: "datavencimento",
        align: "start",
        width: "150",
        sortable: false,
      },
      {
        text: "Status",
        value: "status",
        align: "start",
        width: "100",
        sortable: true,
      },
      { text: "Retorno", value: "dataretorno", align: "center", width: "110" },
      { text: "Advertência", value: "advertencia", sortable: false },
      { text: "Registros", value: "registros", sortable: false },
      {
        text: "",
        value: "historico",
        width: "40",
        align: "start",
        sortable: false,
      },
      {
        text: "",
        value: "actions",
        width: "120",
        align: "start",
        sortable: false,
      },
    ],
    headersHistorico: [
      {
        text: "Gerado em",
        value: "datageracao",
        align: "start",
        width: "100",
        sortable: false,
      },
      {
        text: "Autenticação",
        value: "hash",
        align: "start",
        width: "300",
        sortable: false,
      },
      {
        text: "Origem Autenticação",
        value: "origem",
        width: "200",
        sortable: false,
      },
      { text: "Motivo Autenticação", value: "motivo", sortable: false },
      {
        text: "Liberado Até",
        value: "datavencimento",
        align: "start",
        width: "150",
        sortable: false,
      },
      {
        text: "Acesso de Usuários",
        value: "tipocontrole",
        align: "center",
        width: "150",
        sortable: false,
      },
      {
        text: "Qtd",
        value: "numerolicensas",
        align: "start",
        width: "150",
        sortable: false,
      },
      {
        text: "Status",
        value: "status",
        align: "start",
        width: "100",
        sortable: false,
      },
      {
        text: "Retorno",
        value: "dataretorno",
        align: "start",
        width: "100",
        sortable: false,
      },
      { text: "Advertência", value: "advertencia", sortable: false },
    ],
    motivoChave: "",
    dataset: [],
    footerProps: {
      itemsPerPageOptions: [30, 50, 100, 200, 500, 1000],
      itemsPerPageText: "Itens por página",
    },
  }),
  mounted() {
    this.isControl(true);
    this.setTitle("Autenticação Mensal");
    this.getAutenticacoes();
  },

  watch: {
    dialogChave() {
      if (!this.dialogChave) this.clearCopiarChave();
    },
  },

  methods: {
    ...mapMutations("app", ["setTitle", "isControl"]),
    ...mapMutations("loader", ["showLoader", "hideLoader"]),

    getStringMes(mes) {
      const meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ];
      return meses[mes - 1];
    },

    getDadosNovaHash(hash) {
      function converteBase36ParaBase10(numeroBase36) {
        const valoresBase36 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        let result = 0;
        let baseValue = 1;

        for (let i = numeroBase36.length - 1; i >= 0; i--) {
          const valorDigito = valoresBase36.indexOf(
            numeroBase36[i].toUpperCase()
          );
          if (valorDigito < 0) {
            throw new Error("Número Base36 contém caracteres inválidos.");
          }
          result += valorDigito * baseValue;
          baseValue *= 36;
        }

        return result;
      }

      function getDataVencimento(subHash) {
        var dia = converteBase36ParaBase10(subHash.substr(1, 1));
        var mes = converteBase36ParaBase10(subHash.substr(2, 1));
        var difMes = mes <= 11 ? 0 : mes - 11;
        mes = mes + 1 - difMes;

        var ano = converteBase36ParaBase10(subHash.substr(3, 1));
        ano += difMes;

        return [2000 + ano, mes, dia].join("-");
      }

      function getNumeroLicensas(subHash) {
        return converteBase36ParaBase10(subHash.substr(6, 2));
      }

      function getTipoControleUsuario(subHash) {
        let value = converteBase36ParaBase10(subHash.substr(4, 1)) - 10;
        switch (value) {
          case 1:
            return "Submetido ao grupo";
          case 2:
            return "Individualizado";
          case 9:
            return "Nao se aplica";
          default:
            return "";
        }
      }

      // Aqui começa o código
      let newhash = hash.split("-");

      if (
        newhash[newhash.length - 2].match(/[a-z]/i) ||
        newhash[newhash.length - 1].match(/[a-z]/i)
      ) {
        let subHash = (newhash[5] + newhash[6]).toString();

        let dataVencimento = getDataVencimento(subHash);
        let numeroLicensas = getNumeroLicensas(subHash);
        let tipoControle = getTipoControleUsuario(subHash);

        return { dataVencimento, numeroLicensas, tipoControle };
      } else {
        return { dataVencimento: "", numeroLicensas: "", tipoControle: "" };
      }
    },

    async getData(url, errorMessage) {
      this.showLoader("Carregando Autenticações...");
      try {
        const res = await csapi.get(url);

        if (res.status === 200) {
          return res.data;
        }
      } catch (error) {
        console.error(error);
        this.$toast.error(`${errorMessage}: ${error.message}`);
      } finally {
        this.hideLoader();
      }
    },

    async postData(url, data, errorMessage) {
      try {
        const res = await csapi.post(url, data);
        if (res.status === 200) return res.data;
      } catch (error) {
        console.error(error);
        this.$toast.error(`${errorMessage}: ${error.message}`);
      }
    },

    setFilters() {
      this.filters.items.length = 0;
      try {
        this.filters.items.push({
          text: "Cliente",
          type: "select",
          values: this.dataset
            .map((item) => ({ field: "id", text: item.nome, value: item.id }))
            .sort((a, b) => {
              if (a.text == null || b.text == null) return 0;
              return a.text.localeCompare(b.text);
            }),
        });
      } catch (error) {
        console.error("Não foi possivel gerar o filtro de clientes", error);
      }

      try {
        this.filters.items.push({
          text: "Cliente Self",
          type: "select",
          values: this.dataset
            .map((item) =>
              item.empresas.map((empresa) => ({
                text: empresa.nome,
                value: empresa.nome,
                fn: ([row]) => {
                  const filter = row.empresas.filter(
                    (t) => t.nome === empresa.nome
                  );
                  return filter.length > 0 ? row : null;
                },
              }))
            )
            .flat()
            .sort((a, b) => a.text.localeCompare(b.text)),
        });
      } catch (error) {
        console.error("Não foi possivel gerar o filtro Cliente Self.", error);
      }

      try {
        this.filters.items.push({
          text: "Status Autenticação",
          type: "select",
          values: this.dataset.map((item) => ({
            text: this.getStatusColor(item.status).text,
            value: this.getStatusColor(item.status).text,
            fn: ([row]) => {
              return row.status == item.status ? row : null;
            },
          })),
        });
      } catch (error) {
        console.error(
          "Não foi possivel gerar o filtro Status Autenticação.",
          error
        );
      }

      try {
        this.filters.items.push({
          text: "Chave Copiada",
          type: "select",
          values: this.dataset
            .map((item) => {
              const flatCopia = item.empresas
                .map((empresa) => empresa.copia)
                .flat();
              if (flatCopia.length == 0) {
                return { text: "Todos", value: "Todos", fn: ([row]) => row };
              }
              return {
                text: "Com motivo",
                value: "Com motivo",
                fn: ([row]) => {
                  const filter = row.empresas.filter((t) => t.copia.length > 0);
                  return filter.length > 0 ? row : null;
                },
              };
            })
            .flat()
            .sort((a, b) => a.text.localeCompare(b.text)),
        });
      } catch (error) {
        console.error("Não foi possivel gerar o filtro Chave Copiada.", error);
      }
    },

    async getAutenticacoes() {
      const url = `/v1/autenticacao?ano=${this.initDate.substring(
        0,
        4
      )}&mes=${this.initDate.substring(5, 7)}`;
      const errorMessage = "Erro ao buscar autenticações";
      const data = await this.getData(url, errorMessage);
      this.dataset = this.normalizaDadosAutenticacao(data);

      if (this.dataset.length > 0) this.setFilters();
    },

    async setMotivoCopiarChave(id) {
      const data = {
        motivo: this.motivoChave,
        usuario: `${this.userInfo().login}:${this.userInfo().name}`,
      };

      const url = `/v1/autenticacao/${id}/copia-chave`;
      const errorMessage = "Erro ao definir motivo de copiar chave";
      const res = await this.postData(url, data, errorMessage);

      for (let i = 0; i < this.dataset.length; i++) {
        const item = this.dataset[i];
        const index = item.empresas.findIndex(
          (t) => t.id === res.idautenticacao
        );
        if (index >= 0) {
          this.dataset[i].empresas[index].copia.push(res);
          break;
        }
      }
    },

    getStatusColor(statusArray) {
      const arr = [
        { color: "grey", text: "Sem Ação" },
        { color: "error", text: "Inválido" },
        { color: "error", text: "Erro" },
        { color: "warning", text: "Pendente" },
        { color: "black", text: "Chave Substituída" },
        { color: "info", text: "Enviado" },
        { color: "success", text: "OK" },
      ];

      if (statusArray === null) return arr[0];
      if (statusArray.includes("INVALIDO")) return arr[1];
      if (statusArray.includes("ERRO")) return arr[2];
      if (statusArray.includes("PENDENTE")) return arr[3];
      if (statusArray.includes("CHAVE_SUBSTITUIDA")) return arr[4];
      if (statusArray.includes("ENVIADO")) return arr[5];
      if (statusArray.includes("OK")) return arr[6];
      return arr[0];
    },

    getStatusGeral(statusArray) {
      if (statusArray === null) return null;
      if (statusArray.includes("INVALIDO")) return "INVALIDO";
      if (statusArray.includes("ERRO")) return "ERRO";
      if (statusArray.includes("PENDENTE")) return "PENDENTE";
      if (statusArray.includes("ENVIADO")) return "ENVIADO";
      if (statusArray.includes("OK")) return "OK";
      return null;
    },

    normalizaDadosAutenticacao(data) {
      // Normaliza os dados para exibição
      const newArray = JSON.parse(JSON.stringify(data));
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        item.mes = `${this.getStringMes(item.mes)}/${item.ano}`;
        item.datageracao = new Date(item.datageracao).getTime();
        item.dataretorno =
          item.dataretorno !== null ? new Date(item.dataretorno).getTime() : 0;
        item.origem =
          item.origem == 1 ? "1 - Autenticação mensal" : "2 - Manual";
        item.historico = newArray.filter(
          (t) => t.codigocliente === item.codigocliente
        );
      }

      newArray.length = 0;

      // depois de agrupar no historico, deixa apenas o registro mais recente
      data.sort((a, b) => b.datageracao - a.datageracao);
      for (let i = 0; i < data.length; i++) {
        if (
          newArray.findIndex(
            (t) => t.codigocliente === data[i].codigocliente
          ) == -1
        ) {
          newArray.push(data[i]);
        }
      }

      data = newArray;

      const arrayClientes = data.map((item) => item.cliente);
      const arrayClientesFiltered = new Array();

      for (let i = 0; i < arrayClientes.length; i++) {
        const item = arrayClientes[i];
        if (arrayClientesFiltered.findIndex((t) => t.id === item.id) == -1) {
          arrayClientesFiltered.push(item);
        }
      }

      // Monta parte dos dados utilizados no primeiro nivel de exibição
      for (let i = 0; i < arrayClientesFiltered.length; i++) {
        const item = arrayClientesFiltered[i];
        item.empresas = data.filter((t) => t.cliente.id === item.id);
        const hd = item.empresas[0].serialhd;

        const hd1 = hd.substring(0, 4);
        const hd2 = hd.substring(4, 8);
        const hd3 = hd.substring(8, 12);
        item.idservidor = `${hd1}-${hd2}-${hd3}`;

        const filteredData = data.filter((t) => t.cliente.id === item.id);
        const dataGeracao = filteredData.map((t) => t.datageracao);
        const dataRetorno = filteredData.map((t) => t.dataretorno);
        const status = filteredData.map((t) => t.status);

        item.geradoem = Math.min(...dataGeracao);
        item.autenticadoem = Math.max(...dataRetorno);

        item.status = this.getStatusGeral(status);

        item.autenticadoem =
          item.status == "OK"
            ? new Date(item.autenticadoem).toLocaleString()
            : "Não autenticado";
      }

      for (let i = 0; i < arrayClientesFiltered.length; i++) {
        const empresas = arrayClientesFiltered[i].empresas;
        for (let j = 0; j < empresas.length; j++) {
          delete empresas[j].cliente;
          delete empresas[j].serialhd;

          // parte nova chave
          let extracted = this.getDadosNovaHash(empresas[j].hash);
          empresas[j].datavencimento = extracted.dataVencimento;

          empresas[j].historico.forEach((t) => {
            let e = this.getDadosNovaHash(t.hash);

            t.datavencimento = e.dataVencimento;
            t.numerolicensas = e.numeroLicensas;
            t.tipocontrole = e.tipoControle;
          });
        }
      }

      arrayClientesFiltered.sort((a, b) => a.idweb - b.idweb);

      return arrayClientesFiltered;
    },

    userInfo() {
      const user = JSON.parse(JSON.stringify(this.$store.state.auth.user));
      return user;
    },

    openDialogChave(item) {
      this.itemChave = item;
      this.dialogChave = true;
    },

    clearCopiarChave() {
      this.motivoChave = "";
      this.itemChave = {};
      this.dialogChave = false;
    },

    openDialogHistoryCopiaChave(item) {
      this.itemHistoricoChave = item;
      this.dialogHistoryCopiaChave = true;
    },

    clearHistoryCopiaChave() {
      this.itemHistoricoChave = {};
      this.dialogHistoryCopiaChave = false;
    },

    openDialogHistorico(item) {
      let historico = JSON.parse(JSON.stringify(item.historico));
      this.itemHistorico = historico.sort(
        (a, b) =>
          new Date(b.datageracao).getTime() - new Date(a.datageracao).getTime()
      );
      this.dialogHistorico = true;
    },

    clearHistorico() {
      this.itemHistorico = [];
      this.dialogHistorico = false;
    },

    async copyToClipboard() {
      await this.setMotivoCopiarChave(this.itemChave.id);

      const chave = this.itemChave.hash;

      const input = document.createElement("input");
      document.body.appendChild(input);
      input.value = "";
      input.focus();
      input.select();

      async function copyPageUrl() {
        try {
          await navigator.clipboard.writeText(chave);
          document.body.removeChild(input);
        } catch (err) {
          console.error("Falha ao Copiar: ", err);
          document.body.removeChild(input);
        }
      }

      copyPageUrl();

      this.$toast.success("Chave copiada com sucesso");

      this.clearCopiarChave();
    },
  },
};
</script>

<style>
.autenticacaoTable .v-data-table__wrapper {
  height: calc(100vh - 270px);
  overflow-y: auto;
}

.clientesLogsTable .v-data-table__wrapper {
  height: auto;
  overflow-y: auto;
}
</style>